<template>
  <div
    class="shopping_cart"
    :style="[
      { bottom: isLogin ? '100px' : '50px' },
      {
        paddingBottom:
          deductionTipsShow ||
          (zphIntegral &&
            !deleteShow &&
            allPrice - nonedeductible > 0 &&
            !deductionShow)
            ? '48px'
            : '',
      },
    ]"
  >
    <van-nav-bar
      :title="`购物车（${quantity > 99 ? '99+' : quantity}）`"
      :right-text="isLogin ? (deleteShow ? '完成' : '管理') : ''"
      :fixed="true"
      @click-right="deleteShow = !deleteShow"
    />
    <van-pull-refresh
      v-model="isLoading"
      @refresh="shoppingCartRefresh"
      v-if="isLogin"
    >
      <div
        class="goods_box"
        v-if="shoppingCartList.length || uselessCommodity.length"
      >
        <div
          class="goods_list"
          v-for="(list, index) in shoppingCartList"
          :key="list.id"
        >
          <div class="store">
            <van-checkbox
              v-model="list.isAllSelect"
              @click="dealSelectShop(index)"
              :disabled="list.selectTasting"
            />
            <svg-icon icon-class="store_icon" class="svg_store" />
            <b @click="$router.push(`/store?storeId=${list.storeId}`)">{{
              list.storeName
            }}</b>
            <svg-icon icon-class="home_more_icon" class="svg_more" />
            <span>
              {{ list.freeShipping ? "包邮" : "自提" }}
            </span>
          </div>

          <div
            style="overflow: hidden"
            v-for="(item, i) in list.orderCardListItems"
            :key="i"
          >
            <van-swipe-cell>
              <div class="goods">
                <van-checkbox
                  v-model="item.isSelect"
                  @click="dealSelectGoods(index, i)"
                  :disabled="item.selectTasting"
                />
                <div
                  class="goods_details"
                  @click="
                    $router.push(
                      `/goodsDetails?barcode=${item.barcode}&enterpriseId=${item.enterpriseId}`
                    )
                  "
                >
                  <img v-lazy="item.filePath" />
                  <div class="details">
                    <div>
                      <h4>
                        <span v-if="item.storeTastingActivityInfoEntity"
                          >[新人尝鲜价] </span
                        >{{ item.commodityName }}
                      </h4>
                      <!-- <p>
                    归属品牌：<span>{{ item.brandName }}</span>
                  </p> -->
                      <p>
                        商品规格：<span>{{ item.specsParameter }}</span>
                      </p>
                      <span
                        v-if="item.multiAttribute"
                        class="attribute"
                        @click.stop="selectAttribute(item)"
                        :style="
                          item.multiAttribute && !item.attributeName
                            ? 'color:#f61423'
                            : ''
                        "
                        >{{
                          item.attributeName
                            ? item.attributeName
                            : "请选择商品属性"
                        }}<svg-icon icon-class="arrow_right"
                      /></span>
                      <p
                        class="condition"
                        v-if="
                          userType === 'PURCHASE' && item.conditionSetting == 2
                        "
                      >
                        (起订量≥{{ item.tradeMinimumQuantity + item.prickle
                        }}{{ item.multiple ? ",整倍订货" : "" }})
                      </p>
                      <p
                        class="condition"
                        v-if="
                          userType === 'PURCHASE' && item.conditionSetting == 1
                        "
                      >
                        (起订价≥￥{{ item.tradeStartingPrice | Price }})
                      </p>
                    </div>
                    <b v-if="userType === 'PURCHASE'" class="price">
                      <span class="symbol">￥</span>
                      {{ (item.tradePrice * item.quantity) | Price }}
                      <span
                        class="old_price"
                        v-if="item.preferential && item.type !== 1"
                      >
                        ￥{{ item.oldTradePrice.toFixed(2) }}
                      </span>
                    </b>
                    <b v-else class="price">
                      <span class="symbol">￥</span>
                      {{ (item.retailPrice * item.quantity) | Price }}
                      <span
                        class="old_price"
                        v-if="item.preferential && item.type !== 1"
                      >
                        ￥{{ item.oldRetailPrice.toFixed(2) }}
                      </span>
                    </b>
                  </div>
                </div>
              </div>
              <div class="stepper">
                <p class="tips">
                  <span v-if="!item.deduction"
                    ><svg-icon icon-class="note" />该商品不可抵扣平台积分</span
                  >
                </p>

                <span
                  style="color: #a6a6a8; font-size: 12px"
                  v-if="item.storeTastingActivityInfoEntity"
                  >限购1件</span
                >
                <van-stepper
                  v-else-if="userType === 'PURCHASE'"
                  v-model="item.quantity"
                  :min="
                    item.conditionSetting == 2 ? item.tradeMinimumQuantity : 1
                  "
                  :name="`${item.id}|${item.attributeId}`"
                  @change="updataQuantity"
                  :step="item.multiple ? item.tradeMinimumQuantity : 1"
                />
                <van-stepper
                  v-else
                  v-model="item.quantity"
                  :min="1"
                  :name="`${item.id}|${item.attributeId}`"
                  @change="updataQuantity"
                />
              </div>

              <template #right>
                <van-button
                  square
                  text="删除"
                  type="danger"
                  class="delete-button"
                  @click="deleteGoods(item.id)"
                />
              </template>
            </van-swipe-cell>
          </div>
        </div>

        <div
          class="goods_list useless_commodity"
          v-for="list in uselessCommodity"
          :key="list.storeId"
        >
          <div class="store">
            <!-- <van-checkbox :disabled="true" /> -->
            <svg-icon icon-class="store_icon" class="svg_store" />
            <b>{{ list.storeName }}</b>
            <!-- <span>
              {{ list.freeShipping ? "包邮" : "自提" }}
            </span> -->
          </div>

          <div
            style="overflow: hidden"
            v-for="(item, i) in list.orderCardListItems"
            :key="i"
          >
            <van-swipe-cell>
              <div class="goods">
                <!-- <van-checkbox :disabled="true" /> -->
                <div class="goods_details">
                  <img alt="" v-lazy="item.filePath" />
                  <div class="masking">
                    <p>已失效</p>
                  </div>
                  <div class="details_box">
                    <div class="details">
                      <h4>{{ item.commodityName }}</h4>
                      <!-- <p>
                    归属品牌：<span>{{ item.brandName }}</span>
                  </p> -->
                      <span v-if="item.attributeId" class="attribute">{{
                        item.attributeName
                      }}</span>
                      <p>
                        商品规格：<span>{{ item.specsParameter }}</span>
                      </p>
                    </div>
                    <p class="quantity">x{{ item.quantity }}</p>
                  </div>
                </div>
              </div>
              <!-- <div class="price">
                <b v-if="userType === 'PURCHASE'"
                  ><span>￥</span
                  >{{ (item.tradePrice * item.quantity) | Price }}</b
                >
                <b v-else
                  ><span>￥</span
                  >{{ (item.retailPrice * item.quantity) | Price }}</b
                >
                <span style="color: #999; margin-right: 6px"
                  >x{{ item.quantity }}</span
                >
              </div> -->
              <template #right>
                <van-button
                  square
                  text="删除"
                  type="danger"
                  class="delete-button"
                  @click="deleteGoods(item.id)"
                />
              </template>
            </van-swipe-cell>
          </div>
        </div>
      </div>
      <van-empty
        description="购物车为空"
        v-else-if="
          !isLoading && (!shoppingCartList.length || !uselessCommodity.length)
        "
      >
      </van-empty>
    </van-pull-refresh>
    <van-empty description="您未登录" v-else>
      <van-button
        round
        color="#009A4D"
        :url="`#/login?redirect_uri=${encodeURIComponent($route.fullPath)}`"
        >去登录</van-button
      >
    </van-empty>

    <div class="submit_bar van-hairline--top" v-if="isLogin">
      <div class="deduction_tip" v-if="deductionTipsShow">
        <p>
          你有200元等值积分待领取，下单立享积分抵扣
          <span
            @click="
              $router.replace({
                path: '/home/homePage',
                query: {
                  id: $route.query.id ? $route.query.id : undefined,
                  integralBannerShow: '1',
                },
              })
            "
            >去领取<svg-icon icon-class="arrow_right"
          /></span>
        </p>
      </div>
      <div
        class="deduction_tip"
        v-else-if="availableCoupon || nextCoupon"
        v-show="!deductionShow"
      >
        <p v-if="!availableCoupon">
          再购<span>{{ buyPrice | Price }}</span
          >元，平台积分可抵扣<span>{{ nextCoupon.integral / 100 }}元</span>
        </p>
        <p v-else>
          平台积分已可抵扣<span
            >{{ availableCoupon.integral / 100 }}元
            <template v-if="nextCoupon">
              ，再购{{ buyPrice | Price }}元，可抵扣{{
                nextCoupon.integral / 100
              }}元
            </template></span
          >
        </p>
        <span
          v-if="buyPrice"
          @click="$router.push(`/goodsList?collectOrder=${allPrice}`)"
          >去凑单<svg-icon icon-class="arrow_right"
        /></span>
        <span
          v-else
          @click="$router.push(`/goodsList?collectOrder=${allPrice}`)"
          >再逛逛<svg-icon icon-class="arrow_right"
        /></span>
      </div>

      <van-checkbox
        :disabled="quantity ? false : true"
        v-model="checkAll"
        @click="selectAll"
        >全选</van-checkbox
      >
      <div class="right">
        <div class="all_price" v-show="!deleteShow">
          <p>
            总计：<b><span>￥</span>{{ (allPrice - deducted) | Price }}</b>
          </p>
          <p>
            <b>{{ allSpecies }}</b
            >种共<b>{{ allNum }}</b
            >件
          </p>
        </div>
        <div
          class="detail"
          v-show="zphIntegral && !deleteShow && allPrice - nonedeductible > 0"
        >
          <p @click="deductionShow = !deductionShow">
            明细
            <svg-icon
              icon-class="arrow_right"
              :style="
                deductionShow ? 'transform: scale(0.8) rotate(90deg)' : ''
              "
            />
          </p>
        </div>
        <van-button
          round
          v-show="!deleteShow"
          style="background-color: #009a4d; border-color: #009a4d"
          @click="settlement"
          >去结算</van-button
        >
        <van-button
          round
          v-show="deleteShow && uselessCommodity.length"
          type="danger"
          @click="deleteUselessCommodity"
          >清除失效商品</van-button
        >
        <van-button
          style="margin-left: 10px"
          round
          v-show="deleteShow"
          type="danger"
          @click="deleteGoods(0)"
          >删除</van-button
        >
      </div>
    </div>
    <van-popup
      class="delete_goods"
      v-model="deleteGoodsShow"
      overlay-class="delete-overlay"
    >
      <div class="top">
        <p>删除商品</p>
        <span>确认删除选中的商品？</span>
      </div>
      <div class="bottom">
        <van-button
          plain
          hairline
          type="primary"
          @click="deleteGoodsShow = false"
          >取消</van-button
        ><van-button plain hairline type="primary" @click="deleteGoods(1)"
          >确认</van-button
        >
      </div>
    </van-popup>

    <van-action-sheet
      v-model="deductionShow"
      title="优惠明细"
      class="deduction_popover"
    >
      <span>自动计算优惠，实际以结算页为准</span>
      <div class="content">
        <ul>
          <li>
            <p>商品总价</p>
            <p class="bold"><span>¥</span>{{ allPrice | Price }}</p>
          </li>
          <li v-if="nonedeductible">
            <p>不可抵扣平台积分的商品合计</p>
            <p class="bold"><span>¥</span>{{ nonedeductible | Price }}</p>
          </li>
          <li>
            <p>可抵扣平台积分的商品合计</p>
            <p class="bold">
              <span>¥</span>{{ (allPrice - nonedeductible) | Price }}
            </p>
          </li>
          <li>
            <p>平台积分抵扣</p>
            <div style="display: flex">
              <p style="color: #333333; text-align: end" v-if="availableCoupon">
                已抵扣<span>{{ availableCoupon.integral / 100 }}</span
                >元
              </p>
              <p style="color: #333333; text-align: end" v-if="nextCoupon">
                <template v-if="availableCoupon">，</template>再购<span>{{
                  buyPrice | Price
                }}</span
                >元<br />可抵扣<span>{{ nextCoupon.integral / 100 }}</span
                >元
              </p>
            </div>
          </li>
        </ul>
        <p @click="ruleShow = true">使用规则</p>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="buyShow"
      :safe-area-inset-bottom="false"
      class="buy_show"
      @closed="closeAttribute"
    >
      <div class="content">
        <div class="top">
          <img :src="seleteImg ? seleteImg : goods.filePath" />
          <div class="price">
            <b v-if="userType === 'PURCHASE'"
              ><span>￥</span
              >{{ (goods.tradePrice * goods.quantity) | Price }}</b
            >
            <b v-else
              ><span>￥</span
              >{{ (goods.retailPrice * goods.quantity) | Price }}</b
            >
            <p>已选,{{ goods.quantity }}{{ goods.prickle }}</p>
          </div>
          <svg-icon icon-class="close" @click="buyShow = false" />
        </div>
        <div class="Sku">
          <van-loading
            style="text-align: center; padding: 30px 0"
            v-show="attributeLoading"
          />
          <div class="specification" v-show="!attributeLoading">
            <div
              v-for="(list, index) in goods.storeCommodityAttributeEntities"
              :key="index"
            >
              <p>{{ list.attributeName }}</p>
              <ul>
                <li
                  v-for="(item, idx) in list.attributeValue"
                  :key="idx"
                  :style="
                    list.index == idx ? 'background:#009A4D;color:#fff' : ''
                  "
                  @click="selectAttributeItem(list, idx)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <van-goods-action>
          <van-goods-action-button
            color="#009A4D"
            text="确定"
            @click="updataAttribute"
        /></van-goods-action>
      </div>
    </van-action-sheet>

    <van-overlay
      :show="ruleShow"
      @click="ruleShow = false"
      z-index="99999"
      :lock-scroll="false"
    >
      <div class="wrapper" @click.stop>
        <div class="title">
          <span>使用规则</span>
          <svg-icon icon-class="close" @click="ruleShow = false" />
        </div>
        <div class="content">
          <p v-for="item in integralRule" :key="item.id">
            🌟单笔订单累计满{{ item.amount }}元立减{{
              item.integral / 100
            }}元（{{ item.integral }}积分直接抵扣）
          </p>
          <p>以上积分活动最终解释权归正品汇平台所有。</p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { preferential, btoa, getAvailableCoupon } from "@/utils/validate";
import { getOpenId, removeOpenId } from "@/utils/auth";
export default {
  name: "shopping-cart",
  data() {
    return {
      deleteShow: false,
      checkAll: false,
      value: 1,
      isLoading: false,
      shoppingCartList: [],
      allSum: 0,
      buyShow: false,
      deleteGoodsShow: false,
      goods: {},
      uselessCommodity: [],
      attributeLoading: true,
      seleteImg: "",
      deductionShow: false,
      deductionTipsShow: false,
      availableCoupon: null,
      nextCoupon: null,
      nonedeductible: 0, //不可抵扣
      deducted: 0, //已抵扣
      buyPrice: 0,
      ruleShow: false,
    };
  },
  filters: {
    Price(num) {
      // preferential
      if (num === null) {
        return "-";
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        return num.toFixed(2);
      } else {
        return num;
      }
    },
  },
  computed: {
    ...mapState("user", [
      "isLogin",
      "userInfo",
      "quantity",
      "userType",
      "tzInfo",
      "zphIntegral",
      "bank",
      "integralRule",
    ]),
    allSpecies() {
      var allSpecies = 0;
      for (let shop of this.shoppingCartList) {
        for (let goods of shop.orderCardListItems) {
          if (goods.isSelect) {
            allSpecies += 1;
          }
        }
      }
      return allSpecies;
    },
    allPrice() {
      var allPrice = 0;
      this.nonedeductible = 0;
      for (let shop of this.shoppingCartList) {
        for (let goods of shop.orderCardListItems) {
          if (goods.isSelect) {
            if (this.userType === "PURCHASE") {
              if (!goods.deduction) {
                this.nonedeductible += goods.quantity * goods.tradePrice;
              }
              allPrice += goods.quantity * goods.tradePrice;
            } else {
              if (!goods.deduction) {
                this.nonedeductible += goods.quantity * goods.retailPrice;
              }
              allPrice += goods.quantity * goods.retailPrice;
            }
          }
        }
      }
      let deduction = Number((allPrice - this.nonedeductible).toFixed(2)); //可用积分金额

      if (
        !this.bank.find((item) => {
          return item.bankId === "0";
        }) &&
        allPrice != 0
      ) {
        this.deductionTipsShow = true;
      } else {
        this.deductionTipsShow = false;
      }

      // this.test = [
      //   {
      //     id: "B8D8D4234C7D4339BA3BC7CBC389D628",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 200,
      //     amount: 20,
      //     delete: true,
      //   },
      //   {
      //     id: "8AF173CAEC18446296CF358267E02494",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 500,
      //     amount: 50,
      //     delete: false,
      //   },
      //   {
      //     id: "E85A97CC2BAF49ABA99EEDA365C000F6",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 1000,
      //     amount: 100,
      //     delete: false,
      //   },
      //   {
      //     id: "F0229FD110CB465AB1E09C13742E2B81",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 3000,
      //     amount: 200,
      //     delete: false,
      //   },
      //   {
      //     id: "F0229FD110CB465AB1E09C13742W2B81",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 5000,
      //     amount: 300,
      //     delete: false,
      //   },
      // ];
      if (deduction && this.integralRule && this.zphIntegral) {
        const { availableCoupon, nextCoupon } = getAvailableCoupon(
          this.integralRule,
          deduction,
          this.zphIntegral
        );

        this.availableCoupon = availableCoupon;
        this.nextCoupon = nextCoupon;

        this.nextCoupon
          ? (this.buyPrice = this.nextCoupon.amount - deduction)
          : (this.buyPrice = 0);
        this.availableCoupon
          ? (this.deducted = this.availableCoupon.integral / 100)
          : (this.deducted = 0);
      } else {
        this.availableCoupon = null;
        this.nextCoupon = null;
        this.buyPrice = 0;
        this.deducted = 0;
      }

      return Number(allPrice.toFixed(2));
    },
    allNum() {
      var allNum = 0;
      for (let shop of this.shoppingCartList) {
        for (let goods of shop.orderCardListItems) {
          if (goods.isSelect) {
            allNum += goods.quantity;
          }
        }
      }
      return allNum;
    },
  },
  watch: {
    // shoppingCartList: {
    //   handler: function (val, oldVal) {
    //     console.log(val);
    //   },
    //   deep: true,
    // },
  },
  created() {},
  activated() {
    this.deleteShow = false;
    this.deductionShow = false;
    this.isLogin && this.$store.dispatch("user/getQuantity");
    this.isLogin && this.getShoppingCartList();
  },
  methods: {
    async getShoppingCartList() {
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });

      await this.$API.order
        .getShoppingCartList({
          object: {
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          if (res.data.code === "UNKNOWN_ERROR") throw new Error(res.data.msg);
          if (!res.data.data.length) throw new Error("");
          this.shoppingCartList = [];
          this.uselessCommodity = [];
          this.shoppingCartList = res.data.data;
          this.shoppingCartList.forEach((store) => {
            this.$set(store, "isAllSelect", false);
            this.$set(store, "isSelect", false);
            this.$set(store, "message", "");
            this.$set(store, "freeShipping", true);
            this.$set(store, "isDelete", false);
            this.$set(store, "selectTasting", false); //是否禁用店铺选择框
            this.$set(store, "tastingNum", 0);
            this.$set(store, "isSelectGoodsNum", 0);

            let i = 0;
            store.orderCardListItems.forEach((goods) => {
              // this.$set(goods, "preferential", 0);
              if (goods.storeTastingActivityInfoEntity)
                this.$set(goods, "selectTasting", false); //是否禁用商品选择框

              goods.preferential = preferential(goods);
              goods.oldRetailPrice = goods.retailPrice;
              goods.oldTradePrice = goods.tradePrice;

              if (goods.attributeName)
                goods.attributeName = goods.attributeName.join("/");
              //尝鲜价格
              if (goods.storeTastingActivityInfoEntity) {
                goods.deduction =
                  goods.storeTastingActivityInfoEntity.deduction;
                goods.retailPrice = goods.storeTastingActivityInfoEntity.price;
                goods.preferential = 1;
              } else {
                goods.retailPrice = Number(
                  preferential(goods, "retailPrice").price
                );
              }
              goods.tradePrice = Number(
                preferential(goods, "tradePrice").price
              );

              this.$set(goods, "isSelect", false);
              goods.filePath = goods.filePath.split(",")[0].endsWith(".mp4")
                ? goods.filePath.split(",")[1]
                : goods.filePath.split(",")[0];
              goods.isDelete ? i++ : "";
              store.orderCardListItems.length === i
                ? (store.isDelete = true)
                : "";
            });
          });
          //测试
          // this.shoppingCartList[0].orderCardListItems[0].freeShipping = false;
          // this.shoppingCartList[2].orderCardListItems[0].freeShipping = false;
          // this.shoppingCartList[0].isDelete = true;

          //分开包邮自提
          let oldData = this.shoppingCartList;
          const newData = [];
          for (let index = 0; index < oldData.length; index++) {
            if (oldData[index].isDelete) {
              this.uselessCommodity.push(oldData[index]);

              continue;
            }
            let newStoreT = { ...oldData[index] };
            let newStoreF = { ...oldData[index] };
            let deleteStore = { ...oldData[index] };
            newStoreT.orderCardListItems = [];
            newStoreF.orderCardListItems = [];
            deleteStore.orderCardListItems = [];
            deleteStore.isDelete = true;

            for (let i = 0; i < oldData[index].orderCardListItems.length; i++) {
              if (
                oldData[index].orderCardListItems[i].isDelete ||
                !oldData[index].orderCardListItems[i].enableState
              ) {
                deleteStore.orderCardListItems.push(
                  oldData[index].orderCardListItems[i]
                );
                continue;
              }

              if (oldData[index].orderCardListItems[i].freeShipping) {
                newStoreT.orderCardListItems.push(
                  oldData[index].orderCardListItems[i]
                );
              } else {
                newStoreF.orderCardListItems.push(
                  oldData[index].orderCardListItems[i]
                );
              }
            }

            if (newStoreT.orderCardListItems.length) {
              newData.push(newStoreT);
            }
            if (newStoreF.orderCardListItems.length) {
              newData.push(newStoreF);
              newStoreF.freeShipping = false;
            }
            if (deleteStore.orderCardListItems.length) {
              this.uselessCommodity.push(deleteStore);
            }
          }

          this.shoppingCartList = newData;

          // console.log(this.shoppingCartList[0].orderCardListItems[0]);
          // let obj = [
          //   {
          //     i: "1",
          //     id: "1,2",
          //     createTime: 1670226034000,
          //     updateTime: 1670313817000,
          //     commId: "10092",
          //     attributeName: "颜色",
          //     attributeValue: "红色,绿色",
          //     filePath:
          //       "http://oa.realbrand.net/5d3cfec8751b4079840882c22d36833c.jpg,http://oa.realbrand.net/5d3cfec8751b4079840882c22d36833c.jpg",
          //     defaultFile: "0,1",
          //     delete: null,
          //   },
          // ];
          // this.$set(
          //   this.shoppingCartList[0].orderCardListItems[0],
          //   "storeCommodityAttributeEntities",
          //   obj
          // );
          // this.goods = this.shoppingCartList[0].orderCardListItems[0];
          // if (this.goods.storeCommodityAttributeEntities) {
          //   this.goods.storeCommodityAttributeEntities.forEach((item) => {
          //     this.$set(item, "index", item.i);
          //     item.attributeValue = item.attributeValue.split(",");
          //     item.filePath = item.filePath.split(",");
          //     item.id = item.id.split(",");
          //   });
          // }
          this.isLoading = false;
          this.checkAll = false;
          this.Toast.clear();
        })
        .catch((err) => {
          this.isLoading = false;
          this.Toast.clear();
          if (err.message) this.Toast.fail(err.message);
          console.log(err);
          this.checkAll = false;
        });
    },
    shoppingCartRefresh() {
      this.getShoppingCartList();
      this.$store.dispatch("user/getQuantity");
    },
    //选店铺
    dealSelectShop(index) {
      let shop = this.shoppingCartList[index];
      if (shop.selectTasting) return;
      shop.isSelect = false;
      for (let list of shop.orderCardListItems) {
        list.isSelect = shop.isAllSelect;
        list.isSelect && (shop.isSelect = true);
      }
      // console.log(shop);

      let i = shop.orderCardListItems.findIndex((item) => {
        return item.storeTastingActivityInfoEntity;
      });
      if (i > -1) {
        shop.orderCardListItems.forEach((goods, idx) => {
          if (goods.selectTasting) goods.isSelect = false;
          if (i !== idx && goods.storeTastingActivityInfoEntity) {
            goods.selectTasting = true;
            goods.isSelect = false;
          }
        });
      }
      if (shop.isSelect) {
        this.shoppingCartList.forEach((list, idx) => {
          list.tastingNum = 0;
          list.isSelectGoodsNum = 0;
          list.orderCardListItems.forEach((item) => {
            if (
              i > -1 &&
              index != idx &&
              item.storeTastingActivityInfoEntity &&
              !item.isSelect
            )
              item.selectTasting = true;
            if (item.selectTasting && !item.isSelect) list.tastingNum++;
            if (item.isSelect) list.isSelectGoodsNum++;
          });
          if (list.orderCardListItems.length === list.tastingNum)
            list.selectTasting = true;
          if (
            list.isSelectGoodsNum ===
              list.orderCardListItems.length - list.tastingNum &&
            list.orderCardListItems.length - list.tastingNum !== 0
          )
            list.isAllSelect = true;
        });
      } else {
        let seleteTastingGoods = false;
        this.shoppingCartList.forEach((list, idx) => {
          list.tastingNum = 0;
          list.isSelectGoodsNum = 0;
          list.orderCardListItems.forEach((item) => {
            if (item.storeTastingActivityInfoEntity && item.isSelect) {
              seleteTastingGoods = true;
              list.tastingNum++;
            }
            if (item.isSelect) list.isSelectGoodsNum++;
          });

          if (
            list.isSelectGoodsNum !==
              list.orderCardListItems.length - list.tastingNum &&
            list.tastingNum !== list.isSelectGoodsNum
          ) {
            list.isAllSelect = false;
          }
        });

        if (seleteTastingGoods) {
        } else {
          this.shoppingCartList.forEach((list, idx) => {
            list.tastingNum = 0;
            list.selectTasting = false;
            list.orderCardListItems.forEach((item) => {
              item.selectTasting = false;
            });
          });
        }
      }

      //判断所有商铺是否被选中
      let isSelectAllShop = true;
      for (let shop of this.shoppingCartList) {
        if (!shop.isAllSelect && !shop.selectTasting) {
          isSelectAllShop = false;
        }
      }

      if (isSelectAllShop) {
        // this.isSelectAll = true;
        this.checkAll = true;
      } else {
        // this.isSelectAll = false;
        this.checkAll = false;
      }
    },
    //选商品
    dealSelectGoods(index, i) {
      let shop = this.shoppingCartList[index];
      let goods = shop.orderCardListItems[i];
      if (goods.selectTasting) return;
      shop.isSelect = false;
      //判断所有商品是否被选中
      for (let goods of shop.orderCardListItems) {
        if (goods.isSelect) shop.isSelect = true;
      }
      if (goods.isSelect) {
        this.shoppingCartList.forEach((list) => {
          list.tastingNum = 0;
          list.isSelectGoodsNum = 0;
          list.orderCardListItems.forEach((item) => {
            if (goods.storeTastingActivityInfoEntity) {
              if (item.storeTastingActivityInfoEntity)
                item.selectTasting = true;
              // if (
              //   list.orderCardListItems.length === 1 &&
              //   item.storeTastingActivityInfoEntity
              // )
              //   list.selectTasting = true;
            }
            goods.selectTasting = false; //选中商品
            if (item.isSelect) list.isSelectGoodsNum++;
            if (item.selectTasting) list.tastingNum++;
          });
          if (list.orderCardListItems.length - list.tastingNum === 0)
            list.selectTasting = true;

          if (
            list.isSelectGoodsNum ===
              list.orderCardListItems.length - list.tastingNum &&
            list.orderCardListItems.length - list.tastingNum !== 0
          ) {
            list.isAllSelect = true;
          }
        });
        //当前选中的商品和店铺
        shop.selectTasting = false; //店铺
      } else {
        this.shoppingCartList.forEach((list) => {
          list.tastingNum = 0;
          list.isSelectGoodsNum = 0;
          list.orderCardListItems.forEach((item) => {
            if (goods.storeTastingActivityInfoEntity) {
              if (item.storeTastingActivityInfoEntity) {
                list.selectTasting = false;
                item.selectTasting = false;
              }
            }
            if (item.isSelect) list.isSelectGoodsNum++;
            if (item.selectTasting) list.tastingNum++;
          });
          if (
            list.isSelectGoodsNum !==
            list.orderCardListItems.length - list.tastingNum
          ) {
            list.isAllSelect = false;
          }
        });
      }

      //判断所有商铺是否被选中
      let isSelectAllShop = true;
      for (let shop of this.shoppingCartList) {
        if (!shop.isAllSelect && !shop.selectTasting) {
          isSelectAllShop = false;
        }
      }

      if (isSelectAllShop) {
        // this.isSelectAll = true;
        this.checkAll = true;
      } else {
        // this.isSelectAll = false;
        this.checkAll = false;
      }
    },
    //全选
    selectAll() {
      if (this.checkAll) {
        let tasting = false;
        this.shoppingCartList.forEach((list) => {
          list.orderCardListItems.forEach((item) => {
            if (item.selectTasting) tasting = true;
          });
        });

        for (let list of this.shoppingCartList) {
          if (list.isDelete) continue;
          list.isAllSelect = false;
          list.isSelect = false;
          list.tastingNum = 0;
          list.isSelectGoodsNum = 0;
          for (let item of list.orderCardListItems) {
            if (
              //有选择新人尝鲜商品 && 商品有尝鲜价 && 没有选中的商品
              tasting &&
              item.storeTastingActivityInfoEntity &&
              !item.isSelect
            ) {
              item.selectTasting = true;
            }

            if (item.selectTasting && !item.isSelect) list.tastingNum++;
            //如果商品数量等于
            if (list.orderCardListItems.length === list.tastingNum)
              list.selectTasting = true;

            if (item.selectTasting) {
              item.isSelect = false;
            } else {
              item.isSelect = true;
            }

            if (item.isSelect) list.isSelectGoodsNum++;

            if (
              list.isSelectGoodsNum ===
                list.orderCardListItems.length - list.tastingNum &&
              list.orderCardListItems.length - list.tastingNum !== 0
            ) {
              list.isAllSelect = true;
              list.isSelect = true;
            }

            if (item.storeTastingActivityInfoEntity) tasting = true;

            if (item.isDelete) {
              list.isAllSelect = false;
            }
          }
        }
      } else {
        for (let list of this.shoppingCartList) {
          list.isAllSelect = false;
          list.isSelect = false;
          list.selectTasting = false;
          for (let item of list.orderCardListItems) {
            item.isSelect = false;
            item.selectTasting = false;
          }
        }
      }
    },
    settlement() {
      if (this.allNum) {
        // this.Toast.fail("因商城业务调整，暂不支持下单，请查看公告。");
        // return;

        if (this.tzInfo === null) {
          this.Toast.fail(
            "您当前使用的不是店长的二维码或链接，请联系店长获取二维码或链接"
          );
          return;
        }
        if (this.userType === "PURCHASE") {
          this.Toast.fail("  店长请使用  PDA终端开展店长业务。");
          return;
        }
        this.Toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
        });
        // let tasteNum = 0;
        let multiAttribute = false;
        this.shoppingCartList.forEach((store) => {
          store.orderCardListItems.forEach((goods) => {
            if (goods.isSelect) {
              // if (goods.storeTastingActivityInfoEntity) tasteNum++;
              if (goods.multiAttribute && !goods.attributeId)
                multiAttribute = true;
            }
          });
        });
        // if (tasteNum > 1) {
        //   this.Toast.fail("新人尝鲜价只能选一件哦");
        //   return;
        // }
        if (multiAttribute) {
          this.Toast.fail("请选择商品属性");
          return;
        }

        let arr = JSON.stringify(this.shoppingCartList);
        let goods = JSON.parse(arr);

        for (let i = 0; i < goods.length; i++) {
          goods[i].amount = 0;
          if (goods[i].isSelect) {
            for (let idx = 0; idx < goods[i].orderCardListItems.length; idx++) {
              if (!goods[i].orderCardListItems[idx].isSelect) {
                goods[i].orderCardListItems.splice(idx, 1);
                idx--;
              } else {
                goods[i].amount +=
                  Math.round(
                    goods[i].orderCardListItems[idx].retailPrice *
                      goods[i].orderCardListItems[idx].quantity *
                      100
                  ) / 100;
              }
            }
          } else {
            goods.splice(i, 1);
            i--;
          }
        }

        setTimeout(() => {
          this.Toast.clear();
          sessionStorage.setItem(window.btoa("order"), btoa(goods, 1));
          if (getOpenId() == "undefined" || getOpenId() == "null")
            removeOpenId();
          this.$router.push("/confirmAnOrder");
        }, 500);
      } else {
        this.Toast.fail("请选择商品");
      }
    },

    async deleteGoods(val) {
      if (typeof val === "string") {
        await this.$API.order
          .DeleteOrderCart({
            object: {
              id: [val],
            },
          })
          .then(async (data) => {
            this.deleteGoodsShow = false;
            await this.getShoppingCartList();
            this.$store.dispatch("user/getQuantity");
            this.Toast.success("删除商品成功");
            this.checkAll = false;
            this.deleteShow = false;
          })
          .catch((err) => {
            console.log(err);
            this.Toast.fail("删除商品失败");
          });
      } else {
        const id = [];
        this.shoppingCartList.forEach((store) => {
          store.orderCardListItems.forEach((goods) => {
            if (goods.isSelect) {
              id.push(goods.id);
            }
          });
        });
        if (val === 1) {
          await this.$API.order
            .DeleteOrderCart({
              object: {
                id: id,
              },
            })
            .then(async (data) => {
              this.deleteGoodsShow = false;
              await this.getShoppingCartList();
              this.$store.dispatch("user/getQuantity");
              this.Toast.success("删除所选中的商品成功");
              this.checkAll = false;
              this.deleteShow = false;
            })
            .catch((err) => {
              console.log(err);
              this.Toast.fail("删除商品失败");
            });
          return;
        }
        if (id.length) {
          this.deleteGoodsShow = true;
        } else {
          this.Toast.fail("请选择需要删除的商品");
          return;
        }
      }
    },
    async deleteUselessCommodity() {
      let id = [];
      this.uselessCommodity.forEach((store) => {
        store.orderCardListItems.forEach((goods) => {
          id.push(goods.id);
        });
      });

      await this.$API.order
        .DeleteOrderCart({
          object: {
            id: id,
          },
        })
        .then(async (data) => {
          this.uselessCommodity = [];
          await this.getShoppingCartList();
          this.$store.dispatch("user/getQuantity");
          this.Toast.success("删除失效商品成功");
          this.deleteShow = false;
        })
        .catch((err) => {
          console.log(err);
          this.Toast.fail("删除商品失败");
        });
    },
    //修改商品数量
    updataQuantity(value, detail) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      let id = detail.name.split("|")[0];
      let attributeId = detail.name.split("|")[1];

      this.timeout = setTimeout(async () => {
        await this.$API.order.UpdateOrderCart({
          object: {
            attributeId: attributeId,
            id: id,
            quantity: value,
          },
        });
        this.$store.dispatch("user/getQuantity");
      }, 500);
    },
    async selectAttribute(item) {
      this.attributeLoading = true;
      this.buyShow = true;
      this.goods = item;
      await this.$API.goods
        .getCommodityDetails({
          object: {
            commId: item.commId,
          },
        })
        .then((res) => {
          //商品原规格id
          let goodsAttibuteId = res.data.data.storeCommodityAttributeEntities;
          if (!goodsAttibuteId.length) {
            item.multiAttribute = false;
            this.attributeLoading = false;
            return;
          }
          //用户已选规格id
          let attributeId = [];
          if (this.goods.attributeId) {
            attributeId = this.goods.attributeId.split(",");
          } else {
            goodsAttibuteId.forEach((e) => attributeId.push(""));
          }
          var imgIndex = 0;
          goodsAttibuteId.forEach((item, i) => {
            item.attributeValue = item.attributeValue.split(",");
            item.filePath = item.filePath.split(",");
            item.defaultFile = item.defaultFile.split(",");
            item.id = item.id.split(",");
            if (JSON.parse(item.defaultFile[0])) imgIndex = i;
          });
          attributeId.forEach((item, i) => {
            goodsAttibuteId[i].index = goodsAttibuteId[i].id.findIndex(
              (Item) => {
                return Item == item;
              }
            );
          });
          this.$set(
            this.goods,
            "storeCommodityAttributeEntities",
            goodsAttibuteId
          );
          this.seleteImg =
            goodsAttibuteId[imgIndex].filePath[goodsAttibuteId[imgIndex].index];
          this.attributeLoading = false;
        })
        .catch((e) => {});
    },
    selectAttributeItem(item, i) {
      if (JSON.parse(item.defaultFile[0])) {
        this.seleteImg = item.filePath[i];
      }
      item.index = i;

      // this.seleteImg = item.filePath[i];
      // let idx = goods.findIndex((item) => {
      //   return item.index !== -1;
      // });
      // this.seleteImg = goods[idx].filePath[goods[idx].index];
    },
    async updataAttribute() {
      if (!this.goods.storeCommodityAttributeEntities) {
        this.buyShow = false;
        return;
      }
      let attributeId = [];
      if (this.goods.storeCommodityAttributeEntities) {
        try {
          this.goods.storeCommodityAttributeEntities.forEach((item) => {
            if (item.index === -1) {
              throw Error(`请选择商品${item.attributeName}`);
            }
            attributeId.push(item.id[item.index]);
          });
        } catch (e) {
          this.Toast.fail({
            message: e.message,
            className: "attribute_toast",
          });
          return;
        }
      }
      //当前属性id
      let select = this.goods.attributeId.split(",");
      let id = [];
      //选中的属性id
      this.goods.storeCommodityAttributeEntities.forEach((attribute) => {
        id.push(attribute.id[attribute.index]);
      });
      let f = false;
      select.forEach((item, i) => {
        if (item !== id[i]) f = true;
      });
      // let type;
      // let typeId;
      // if (this.goods.storeTastingActivityInfoEntity) {
      //   typeId = this.goods.storeTastingActivityInfoEntity.id;
      //   type = 1;
      // }
      if (f) {
        id = id.join(",");
        await this.$API.order
          .UpdateOrderCart({
            object: {
              quantity: this.goods.quantity,
              attributeId: id,
              id: this.goods.id,
              // type: type,
              // typeId: typeId,
            },
          })
          .then(async (res) => {
            if (res.data.code === "SUCCESS") {
              this.Toast.success("修改属性成功");
              await this.getShoppingCartList();
            } else {
              this.Toast.fail(res.data.msg);
            }
          })
          .catch((e) => {
            this.Toast.fail(e);
            console.log(e);
          });
      }

      this.buyShow = false;
    },
    closeAttribute() {
      this.goods = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.shopping_cart {
  background: #f5f5f5;
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 100px;
  max-width: 500px;
  margin: 0 auto;
  overflow-y: scroll;
  padding: 12px 15px;
  ::v-deep .van-nav-bar {
    max-width: 500px;
    margin: 0 auto;
    right: 0;
  }
  ::v-deep .van-pull-refresh {
    min-height: 100%;
    .Loading {
      position: absolute;
      left: 50%;
      top: 150px;
      margin-left: -15px;
    }
    .van-loading {
      display: none;
    }
  }
  .goods_box {
    background: #f7f7f7;
    .goods_list {
      padding: 12px;
      border-radius: 5px;
      background: #fff;
      margin-bottom: 12px;
      ::v-deep .van-checkbox {
        margin-right: 12px;
        .van-checkbox__icon {
          font-size: 18px;
        }
        .van-checkbox__icon--checked .van-icon {
          background-color: #009a4d;
          border-color: #009a4d;
        }
      }
      .store {
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 40px;
        .van-checkbox {
          flex-shrink: 0;
        }
        b {
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .svg_store {
          font-size: 12px;
          margin-right: 6px;
          flex-shrink: 0;
        }
        .svg_more {
          color: #999999;
          font-size: 10px;
          margin-left: 2px;
          flex-shrink: 0;
        }
        span {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .goods {
        margin-top: 12px;
        display: flex;
        .van-checkbox {
          flex-shrink: 0;
        }
        .goods_details {
          display: flex;
          flex: 1;
          position: relative;
          img {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            margin-right: 12px;
          }
          .masking {
            width: 100px;
            height: 100px;
            background: rgba($color: #fff, $alpha: 0.6);
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              color: #fff;
              padding: 2px 14px;
              border-radius: 12px;
              line-height: 20px;
              background: rgba($color: #000000, $alpha: 0.6);
            }
          }
          .details {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h4 {
              line-height: 22px;
              color: #333333;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              span {
                color: #fb4e10;
                font-weight: normal;
              }
            }
            p {
              line-height: 20px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 12px;
              color: #999999;
              width: 100%;
              span {
                color: #333333;
              }
            }
            .attribute {
              margin: 2px 0;
              display: inline-block;
              background: #f1f1f1;
              color: #878787;
              padding: 0 10px;
              line-height: 20px;
              border-radius: 10px;
              .svg-icon {
                margin-left: 6px;
                font-size: 8px;
                vertical-align: baseline;
                transform: rotate(90deg);
              }
            }
            .condition {
              color: #009a4d;
            }
            .price {
              font-size: 16px;
              color: #fb4e10;
              padding-right: 20px;
              .symbol {
                font-size: 12px;
                font-weight: normal;
                display: inline-block;
                transform: scale(0.8);
                -webkit-transform: scale(0.8);
                -moz-transform: scaleY(0.8);
                position: relative;
                left: 1px;
                top: 1px;
              }
              .old_price {
                font-size: 12px;
                font-weight: normal;
                color: #999;
                text-decoration: line-through;
              }
            }
          }
        }
      }
      .stepper {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        .tips {
          padding-left: 30px;
          font-size: 12px;
          color: #a6a6a8;
          .svg-icon {
            margin-right: 4px;
          }
        }
      }
      .delete-button {
        height: 100%;
      }
    }
    .goods_list:last-child {
      margin: 0;
    }
    .useless_commodity {
      .details_box {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .details {
        flex: 1;
        justify-content: flex-start !important;
        align-items: flex-start;
      }
      .quantity {
        text-align: end;
        font-size: 13px;
        color: #999;
      }
    }
  }
  ::v-deep .van-nav-bar {
    .van-nav-bar__text {
      color: #333333;
    }
  }

  .submit_bar {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    height: 50px;
    background: #fff;
    padding: 7px 15px;
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    .deduction_tip {
      position: absolute;
      left: 0;
      right: 0;
      top: -36px;
      background: #fef6d1;
      display: flex;
      font-size: 12px;
      height: 36px;
      align-items: center;
      justify-content: center;
      p {
        color: #333333;
        margin-right: 16px;
      }
      span {
        color: #fb4e10;
        .svg-icon {
          font-size: 10px;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scaleY(0.8);
          vertical-align: unset;
        }
      }
    }

    ::v-deep .van-checkbox {
      margin-left: 12px;
      .van-checkbox__icon {
        font-size: 18px;
      }
      .van-checkbox__icon--checked .van-icon {
        background-color: #009a4d;
        border-color: #009a4d;
      }
      .van-checkbox__label {
        color: #666666;
        margin-left: 6px;
      }
    }
    .right {
      display: flex;
      .all_price {
        margin-right: 12px;
        line-height: 18px;
        text-align: end;
        b {
          color: #fb4e10;
          span {
            font-size: 12px;
            font-weight: normal;
            display: inline-block;
            transform: scale(0.8);
            -webkit-transform: scale(0.8);
            -moz-transform: scaleY(0.8);
            position: relative;
            left: 1px;
            top: 1px;
          }
        }
      }
      .detail {
        margin-right: 18px;
        font-size: 12px;
        font-weight: 400;
        color: #fb4e10;
        // p {
        //   // display: flex;
        //   // align-items: center;
        // }
        .svg-icon {
          font-size: 10px;
          vertical-align: unset;
          transition: 0.2s all;
          transform: scale(0.8) rotate(-90deg);
          -webkit-transform: scale(0.8) rotate(-90deg);
          -moz-transform: scaleY(0.8) rotate(-90deg);
        }
      }
      .van-button {
        height: auto;
        color: #fff;
      }
    }
  }
  .buy_show {
    overflow: unset;
    // height: 60%;
    z-index: 1000000 !important;
    .van-action-sheet__content {
      overflow: unset;
    }
    .content {
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        position: relative;
        top: -16px;
        z-index: 9999;
        padding: 0 15px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 5px;
          margin-right: 12px;
        }
        .price {
          display: flex;
          flex-direction: column;
          justify-content: center;
          b {
            span {
              font-size: 12px;
              font-style: normal;
              display: inline-block;
              transform: scale(0.8);
              -webkit-transform: scale(0.8);
              -moz-transform: scaleY(0.8);
              position: relative;
              left: 2px;
              top: 1px;
              z-index: 0;
            }
            margin-left: -4px;
            font-size: 16px;
            line-height: 24px;
            color: #fb4e10;
          }
          p {
            line-height: 20px;
            color: #333333;
          }
        }
        .svg-icon {
          position: absolute;
          top: 39px;
          right: 15px;
          color: #868686;
        }
      }
      .Sku {
        height: 30vh;
        overflow-y: scroll;
        padding: 0 15px;
        margin-bottom: 15px;
        .specification {
          > div {
            p {
              margin-bottom: 6px;
            }
            ul {
              display: flex;
              flex-wrap: wrap;
              li {
                margin-bottom: 12px;
                transition: 0.2s all;
                line-height: 26px;
                background: #f5f5f5;
                border-radius: 13px;
                padding: 0 10px;
                color: #666;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .van-goods-action {
      padding: 0 15px;
      position: unset;
      height: 34px;
      flex: 1;
      .van-button {
        margin: 0;
        height: 34px;
        font-size: 16px;
      }
    }
  }

  .deduction_popover {
    // position: absolute;
    padding-bottom: 100px;
    border-radius: 5px 5px 0px 0px;
    .van-action-sheet__header {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: normal;
      padding: 24px 0 10px;
      .van-icon {
        top: auto;
      }
    }

    .van-action-sheet__content {
      text-align: center;
      > span {
        font-size: 12px;
        color: #999999;
      }
      .content {
        padding: 28px 15px;
        li {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          color: #666666;
          span {
            color: #fb4e10;
          }
        }
        .bold {
          font-weight: bold;
          font-size: 16px;
          color: #fb4e10;
          span {
            font-size: 12px;
            font-weight: normal;
            padding-right: 1px;
          }
        }
        li:last-child {
          margin-bottom: 0;
        }
        > p {
          text-align: start;
          font-size: 12px;
          font-weight: bold;
          margin-top: 6px;
          color: #333333;
        }
      }
    }
  }
  .van-empty {
    .van-button {
      padding: 0 40px;
    }
  }
  ::v-deep .delete-overlay {
    z-index: 99999 !important;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .delete_goods {
    z-index: 100000 !important;
    border-radius: 16px;
    width: 320px;
    .top {
      max-height: 60vh;
      padding: 26px 24px;
      text-align: center;
      -webkit-box-flex: 1;
      white-space: pre-wrap;
      word-wrap: break-word;
      p {
        line-height: 24px;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 500;
      }
      span {
        line-height: 20px;
        color: #646566;
      }
    }
    .bottom {
      display: flex;
      .van-button {
        flex: 1;
        border-color: #ebedf0;
      }
      .van-button:nth-child(1) .van-button__text {
        color: #000000;
      }
      .van-button:nth-child(2) .van-button__text {
        color: #ee0a24;
      }
      .van-button::after {
        border-bottom: 0;
      }
      .van-button:nth-child(1)::after {
        border-left: 0;
      }
      .van-button:nth-child(2)::after {
        border-left: 0;
        border-right: 0;
      }
    }
  }

  .van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper {
      background: #fff;
      width: 70%;
      height: 40%;
      border-radius: 20px;
      padding: 20px;
      padding-bottom: 40px;
      .title {
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        span {
          font-size: 16px;
        }
        .svg-icon {
          color: #666;
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
      .content {
        overflow-y: scroll;
        height: 90%;
        p {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.attribute_toast {
  z-index: 10000001 !important;
}
</style>
